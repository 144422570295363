.authors {
  &-header {
    padding: 6rem 0;
    position: relative;
    text-align: center;
    background-color: $secondary;
    color: $white;

    &-bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      opacity: .3;
    }

    &-content {
      position: relative;
      z-index: 2;
      max-width: 500px;
      margin: 0 auto;
      line-height: 1.5;

    }
  }


}

.authorsService {
  background: $white;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  padding: 2rem;

  &-header {
    display: flex;
    align-items: center;
    margin-bottom: .8rem;


  }

  &-icon {
    width: 3rem;
    height: 3rem;
    border-radius: 20rem;
    background: rgba($secondary, .07);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: .5rem;

    img {
      max-width: 1.5rem;
      max-height: 1.5rem;
    }


  }
}

.authorsCta {
  background: $primary;
  color: $white;
  padding: 4rem 0;
  margin-bottom: -4rem;
  background-image: url('../static/graphic/3@2x.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  background-attachment: fixed;

  &-title {
    max-width: 500px;
    margin: 0 auto 1rem;
  }
}
