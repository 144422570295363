.theHeader {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: $zindex-fixed;
  color: $white;
  background: $secondary;

  &-logo {
    display: block;
    flex: 0 0 140px;
    width: 140px;
    height: 33px;
    color: $white;

    @include media-breakpoint-down(md) {
      flex: 0 0 120px;
      width: 120px;
      height: 30px;
    }

    &:hover {
      color: $white;
    }
  }

  &-main {
    &-row {
      height: $header-height;
      display: flex;
      align-items: center;
      justify-content: space-between;


    }


    @include media-breakpoint-down(md) {
      overflow: unset;
      max-height: $header-height;
    }
  }


  &-nav {
    display: flex;
    margin: 0 -5px;
    padding: 0;
    list-style-type: none;

    .icon {
      font-size: 1.2rem;
    }

    ul {
      margin: 0;
      list-style-type: none;
      padding: 0;
    }

    a {
      display: flex;
      align-items: center;
      margin-left: -2px;
      margin-right: -2px;
      position: relative;
      justify-content: space-between;

      >* {
        padding-left: 2px;
        padding-right: 2px;
      }


    }

    >li {
      position: relative;
      display: flex;
      align-items: center;
      padding: 0 10px;
      min-height: 40px;
      font-weight: $headings-font-weight;

      >a:not(.btn) {
        position: relative;

        &:hover {
          color: $primary;
        }
      }

      .btn-circle {
        padding: 0.375rem 0.195rem;
        font-size: 1.2rem !important;
      }

      ul {
        position: absolute;
        top: 100%;
        right: 0;
        background: $white;
        color: $body-color;
        z-index: 4;
        display: none;
        transition: 300ms ease;
        box-shadow: $box-shadow;
        min-width: 200px;
        padding: .5rem 0;

        &.is-left {
          right: auto;
          left: 0;
        }

        &.is-auto {
          min-width: 1px;
        }

        >li {
          >a {
            padding: 5px 20px;
            font-size: 12px;
            white-space: nowrap;
            display: flex;
            transition: .2s;
            justify-content: space-between;

            &:hover {
              background: rgba($primary, 1);
              color: $white;
            }
          }
        }


      }

      >ul {
        >li {
          >ul {
            top: 0;
            right: 100%;
          }
        }

      }

      &:hover {
        >ul {
          display: block;

          >li {
            &:hover {
              >ul {
                display: block;
              }
            }
          }
        }
      }
    }

    &-num {
      position: absolute;
      top: 0px;
      right: -2px;
      font-size: 8px;
      background: $primary;
      width: 12px;
      height: 12px;
      display: flex;
      line-height: 1;
      align-items: center;
      justify-content: center;
      border-radius: 20em;
      color: $white;
    }
  }

  &-search {
    width: 420px;
    display: flex;
    background: $white;
    box-shadow: $box-shadow;
    border-radius: $border-radius;
    color: $dark;

    @media (max-width: 1300px) {
      width: 350px;
    }

    @include media-breakpoint-down(lg) {
      position: absolute;
      left: 0;
      right: 0;
      top: 100%;
      width: 100% !important;
      z-index: 2;
      display: none;

      &.is-active {
        display: flex;
      }
    }

    .choices__inner {
      border: 0;
      border-radius: 0 $border-radius $border-radius 0;
      background: rgba($gray-400, 0.5);
      height: 30px;
      min-height: 30px;
    }

    .choices__list--single {
      padding-top: 0;
      padding-bottom: 0;
    }

    .form-control {
      border: 0;
      border-radius: 0;
      height: 30px;
    }


    .btn {
      padding-right: 0;
      padding-left: 0;
      width: 40px;
      height: 30px;
      flex: 0 0 40px;
      font-size: 15px;
      border-radius: $border-radius 0 0 $border-radius;

      span {
        display: block;
      }
    }

    &-select {
      min-width: 140px;
    }
  }

  @media (min-width: 1300px) {
    .d-xxl {
      &-none {
        display: none !important;

      }

      &-flex {
        display: flex !important;
      }

      &-block {
        display: block !important;
      }
    }
  }


  &.is-sticky {
    transition: transform 0.25s ease-in-out, background 0.25s ease-in-out;
    will-change: transform;
  }

  &.is-unpinned {
    transform: translateY(-100%);


  }

  &.is-pinned {
    transform: translateY(0);
  }

  &.is-not-top {
    box-shadow: 0px 0px 10px rgba($secondary, 0.1);

    .theHeader {

      &-top,
      &-middle {
        border: 0;
        max-height: 0px;
      }

      &-middle {
        @include media-breakpoint-down(md) {
          max-height: 100px;
        }
      }
    }
  }
}

.logoColor {
  fill: $primary;
}
