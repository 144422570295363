.fullPage {
  background-color: $primary;
  min-height: 100vh;
  background-image: url('../static/graphic/3@2x.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;

  .theApp-body {
    padding-top: 0;
    min-height: 100vh;
    display: flex;
    padding: 20px;
  }

  .theHeader,
  .theFooter {
    display: none;
  }

  &-card {
    margin: auto;
    width: 800px;
    max-width: 100%;
  }

  &-content {
    background: $white;
    box-shadow: $box-shadow;
    padding: 4rem 2rem;
    border-radius: $border-radius;

    a:not(.btn) {
      &:hover {
        color: $primary;
      }

    }
  }

  .input {
    background: none;
    border-color: $white;
    color: $white;
  }

  &-logo {
    flex: 0 0 140px;
    width: 140px;
    height: 33px;
    display: inline-flex;
    color: $white !important;

    .logoColor {
      fill: $white;
    }

    svg {
      width: 100%;
      height: auto;
    }

    &:hover {
      color: $white;
    }
  }

  &-socials {
    display: flex;
    margin: 0;
    padding: 0;
    margin-left: -5px;
    margin-left: -5px;
    list-style-type: none;

    @include media-breakpoint-down(sm) {
      display: block;
    }

    >* {
      padding-left: 5px;
      padding-right: 5px;

      @include media-breakpoint-up(md) {
        flex: 0 0 33%;
        width: 33%;
      }

      @include media-breakpoint-down(sm) {
        margin-bottom: 1rem;
      }

    }

    .icon {
      background: $facebook;
      color: $white;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 20em;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-left: .5rem;
      font-size: 12px;
    }

    .btn {
      width: 100%;
      background: rgba($facebook, .1);
      display: flex;
      align-items: center;
      justify-content: center;
      color: $facebook;


      &:hover {
        background: rgba($facebook, .2);
      }

      &.is-twitter {
        background: rgba($twitter, .1);
        color: $twitter;

        .icon {
          background: $twitter;
        }

        &:hover {
          background: rgba($twitter, .2);
        }
      }

      &.is-google {
        background: rgba($google, .1);
        color: $google;

        .icon {
          background: $google;
        }

        &:hover {
          background: rgba($google, .2);
        }
      }

    }


  }
}
