$theme-colors: ("primary": #ff8769,
  "secondary": #1f2b3e,
  "danger": #d73b15,
  "warning": #F2C13D,
  "success": #8eb711,
  "dark": #000000,
  "info": #d0ddf4);

$primary: #ff8769;
$secondary: #1f2b3e;
$info: #d0ddf4;
$danger: #d73b15;
$warning: #F2C13D;
$success: #8eb711;
$dark: #000000;
$body-bg: #F5F9FF;
$body-color: #000000;
$gray-400: #d0ddf4;
$facebook: #3b5998;
$twitter: #1da1f2;
$google: #4285f4;
$whatsapp: #075e54;
$sports: #f9f5ec;
$link-color: $secondary;
$headings-font-weight: 700;
$font-family-base: "Dubai";
$headings-font-family: "Dubai";
$header-height: 80px;
$header-height-xs: 100px;
$box-shadow: 0px 12px 30px rgba(30, 44, 62, 0.06);
$box-shadow2: 0 0 16.92px 1.08px rgba(36, 64, 95, 0.07);
$link-hover-decoration: none;
$border-radius-card: 1rem;
$input-btn-padding-x:1rem;
$input-btn-font-size-lg:1rem;
$line-height-base: 1.3;
$headings-line-height: 1.5;
$border-radius: 4px;
$grid-gutter-width: 20px;
// $input-height: 40px;
$pagination-bg:none;
$pagination-border-width:0;
$pagination-border-radius:0;
// $container-max-widths: (
//   sm: 800px,
//   md: 900px,
//   lg: 960px,
//   xl: 1140px
// );
@import "node_modules/bootstrap/scss/bootstrap";

// @import "node_modules/bootstrap/scss/bootstrap-reboot.scss";
// @import "node_modules/bootstrap/scss/bootstrap-grid.scss";

@function aspectRatio($width, $height) {
  @return ($height / $width) * 100%;
}


@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: lighten($primary, 10%);
    outline: 0 solid $gray-400;
    border-radius: 20em;

    &:hover {
      background: $primary
    }
  }


}
