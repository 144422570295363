.appPublisher {
  transition: 200ms;
  position: relative;
  height: 100%;
  z-index: 2;
  overflow: hidden;
  background: $white;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  border-radius: $border-radius;
  transition: .3s ease;

  @include media-breakpoint-down(xs) {
    padding: 2rem 1rem;
  }

  &-url {
    position: absolute;
    z-index: 4;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &-header {
    img {
      max-width: 100%;
      max-height: 35px;
    }
  }

  &-title {
    margin: 1rem 0;
  }

  &:hover {
    transform: translateY(-5px);
  }

}

.searchInput {
  position: relative;

  &-input {
    padding-left: 3rem;
  }

  .btn {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }
}
