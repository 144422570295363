.contactCard {
  display: flex;

  background: $white;
  padding: 1rem;
  border-radius: $border-radius;
  margin-bottom: 1rem;

  &-icon {
    font-size: 30px;
    margin-left: 1rem;

  }

}

.contactMap {
  margin-bottom: -4rem;

  iframe {
    display: block;
  }
}
