$choices-bg-color: $white;
$choices-keyline-color: $gray-400;
$choices-primary-color: $primary;
$choices-border-radius: $border-radius;
@import "node_modules/choices.js/src/styles/choices.scss";

.choices__inner {
  min-height: 40px;
}

.choices[data-type*="select-one"] .choices__inner {
  padding-top: 5.5px;
  padding-bottom: 5.5px;
}

.choices__inner {
  padding-top: 4.5px;
  padding-bottom: 2.5px;
}

.choices {
  * {
    word-break: break-word !important;
  }
}

[dir="rtl"] .choices__list--dropdown .choices__item--selectable {
  padding-left: 10px;
}

.choices__list--dropdown {
  z-index: 5;
}

.choices[data-type*="text"] .choices__button {
  margin-right: 8px;
  margin-left: -4px;

  padding-left: 0;
  padding-right: 16px;
  border-left: 0;
  border-right: 1px solid rgba($secondary, 0.2);
}
