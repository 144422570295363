$vanillatop-background:$primary !default; //color of the button
$vanillatop-color: #fff !default; //color of the text in the button
$vanillatop-size: 40px !default; //size of the button
$vanillatop-position-right: 10px !default; //position from the right border of the screen
$vanillatop-position-bottom: 40px !default; //position from the bottom of the screen

a {
  &.vanillatop {
    background-color: $vanillatop-background;
    border-radius: 50%;
    bottom: $vanillatop-position-bottom;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12), 0 3px 1px -2px rgba(0, 0, 0, .2);
    display: block;
    height: $vanillatop-size;
    line-height: $vanillatop-size;
    position: fixed;
    right: $vanillatop-position-right;
    text-align: center;
    transform: translateX(120px);
    transition: 1s all;
    vertical-align: middle;
    width: $vanillatop-size;
    z-index: 9999;

    &::before {
      color: $vanillatop-color;
      content: '▲';
      font-size: 120%;
    }

    &:link {
      text-decoration: none;
    }

    &:hover {
      background-color: lighten($vanillatop-background, 6%);
      box-shadow: 0 3px 3px 0 rgba(0, 0, 0, .14), 0 1px 7px 0 rgba(0, 0, 0, .12), 0 3px 1px -1px rgba(0, 0, 0, .2);
    }

    @media only screen and (min-width: 768px) {
      bottom: $vanillatop-position-bottom - 20px;
      right: $vanillatop-position-right + 10px;
    }

    @media only screen and (min-width: 1024px) {
      bottom: $vanillatop-position-bottom - 10px;
      height: $vanillatop-size + 10px;
      line-height: $vanillatop-size + 10px;
      right: $vanillatop-position-right + 20px;
      width: $vanillatop-size + 10px;
    }

  }
}
