$fontsPath: '../static/fonts/';

@font-face {
  font-family: 'Dubai';
  src: url('#{$fontsPath}DubaiW23-Regular.eot');
  src: url('#{$fontsPath}DubaiW23-Regular.eot?#iefix') format('embedded-opentype'),
  url('#{$fontsPath}DubaiW23-Regular.woff2') format('woff2'),
  url('#{$fontsPath}DubaiW23-Regular.woff') format('woff'),
  url('#{$fontsPath}DubaiW23-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Dubai';
  src: url('#{$fontsPath}DubaiW23-Bold.eot');
  src: url('#{$fontsPath}DubaiW23-Bold.eot?#iefix') format('embedded-opentype'),
  url('#{$fontsPath}DubaiW23-Bold.woff2') format('woff2'),
  url('#{$fontsPath}DubaiW23-Bold.woff') format('woff'),
  url('#{$fontsPath}DubaiW23-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
