.book {
  background: $white;
  padding: 4rem 0;

  &-meta {
    padding: 0;
    margin: 0;
    list-style-type: none;
    color: rgba($secondary, .5);

    &:not(:first-child) {
      margin-top: 10px;
    }

    >* {
      display: block;

      &:not(:first-child) {
        margin-top: 5px;
      }
    }

  }

  .appStars {

    &-colored,
    &-uncolored {
      font-size: 1.3rem;
    }
  }

  &-price {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;


    >* {
      margin-left: 5px;
      margin-right: 5px;
      margin-bottom: 10px;
    }

    &-item {
      text-align: center;
      position: relative;
      z-index: 2;
      cursor: pointer;
      padding: 1rem;
      overflow: hidden;
      border-radius: $border-radius;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      max-width: 220px;
      font-size: 12px;

      &-bg {
        background: rgba($primary, .2);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: -1;
      }

      &-header {
        display: flex;
        justify-content: center;
        margin-bottom: .5rem;
      }

      &-icon {
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 20em;
        border: 1px solid $primary;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $primary;
        margin-left: .5rem;

        line-height: 1;

        .icon {
          display: none;
        }
      }
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;

      &:checked {
        ~.book-price-item-bg {
          background: $primary;
        }

        ~* {
          color: $white;
        }

        ~.book-price-item-header {
          .book-price-item-icon {
            background: $white;

            .icon {
              display: inline-flex;
            }
          }
        }
      }
    }
  }

  &-tabs {
    position: relative;
    text-align: center;
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    a:hover {
      color: $primary;
    }

    >li {
      padding: 0 10px 10px;

      &.active {
        >a {
          color: $primary;
        }
      }
    }

    &-content {
      background: $white;
      border-radius: $border-radius;
      padding: 4rem 2rem;
    }

    &-list {
      list-style-type: none;
      padding: 0;
      margin: 0;

      >li {
        padding: .5rem;
        background: $body-bg;
        border-radius: $border-radius;
        margin-bottom: 1rem;

      }
    }
  }

  &-reviews {
    background: rgba($warning, .2);
    color: $warning;
    padding: 2rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;


    .appStars {
      font-size: 1.5rem;
    }
  }
}


.starRating {
  position: relative;
  display: inline-flex;
  flex-direction: row-reverse;
  font-size: 30px;

  &:not(:checked) {
    &>input {
      position: absolute;
      opacity: 0;
      transform: translateX(99999px);
    }

    &>label {
      width: 1em;
      overflow: hidden;
      white-space: nowrap;
      cursor: pointer;

      color: $info;

      &:before {
        content: '★ ';
      }

      &:hover {
        color: $warning;

        &~label {
          color: $warning;
        }
      }
    }
  }

  >input {
    &:checked {
      &~label {
        color: $warning;
      }

      &+label:hover,
      &+label:hover~label,
      &~label:hover,
      &~label:hover~label {
        color: $warning;
      }
    }
  }

  label:hover~input:checked~label {
    color: $warning;
  }
}
