.socials {
  display: flex;
  margin: 0 -0.5rem;
  list-style-type: none;
  padding: 0;

  > li {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  a {
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: 200ms ease-in-out;

    &:hover {
      border-color: $primary;
      color: $primary;
      transform: translateY(-1px);
    }
  }
}
