.navOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9998;
  transform: translateY(-100%);
  // transition: .5s ease-in-out;
  transition-delay: 0.7s;

  &-container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    max-width: 100%;
    overflow-x: hidden;
    z-index: 9998;
    overflow-y: auto;
    opacity: 1;
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: center;
    max-height: 100vh;

    &:after,
    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      background: $body-bg;
      width: 50vw;
      z-index: -1;
      transition: all 500ms ease-in-out;
      transform: translateY(100%);
      transition-delay: 200ms;
    }

    &:before {
      right: auto;
      left: 0;
      z-index: -1;
      transition: all 500ms ease-in-out;
      transform: translateY(-100%);
      transition-delay: 200ms;
    }
  }

  &-col {
    width: 100%;
    max-width: 800px;
    transition: all 500ms ease-in-out;

    transform: translate(0, -100%);
    opacity: 0;
    transition-delay: 0ms;

    @include media-breakpoint-down(md) {
      width: 100%;
    }

    &:last-child {
      transform: translate(0, 100%);

    }
  }

  &-list {
    display: block;
    padding: 0;
    margin: 0;
    list-style-type: none;
    text-align: center;

    &-container {
      overflow-x: hidden;
      z-index: 9998;
      overflow-y: auto;
      max-height: calc(100vh - 60px);
      margin-top: 60px;
      padding: 0 2rem;
    }

    >li {
      display: block;
      margin-bottom: 1rem;

      >a {
        display: inline-block;
        font-weight: $headings-font-weight;
        transition: 200ms ease-in-out;
        text-transform: uppercase;


        @include media-breakpoint-down(md) {
          text-align: center;
        }

        &:hover {
          text-decoration: none;
          color: $primary;
        }
      }

      >ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
      }
    }
  }

  &.is-active {
    transform: none;
    transition-delay: 0s;

    .navOverlay {
      &-container {

        &:after,
        &:before {
          transform: none;
          transition-delay: 0s;
        }
      }

      &-col {
        transform: none;
        opacity: 1;
        transition-delay: 500ms;
      }

      &-close {
        opacity: 1;
      }
    }
  }

  &-close-btn {
    position: absolute;
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 12;
  }
}
