.homeIntro {
  background-color: $secondary;
  color: $white;
  position: relative;
  height: 100vh;
  min-height: 700px;
  display: flex;
  align-items: center;

  @include media-breakpoint-down(md) {
    height: auto;
    padding: 5rem 0
  }

  &-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url("../static/graphic/1@2x.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;
  }

  &-title {
    max-width: 600px;
  }

  &-content {
    position: relative;
    z-index: 2;
    padding-top: $header-height;
  }

  &-card {
    background: $white;
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $dark;
    padding: 2rem 1rem;
    transition: 0.3s ease;
    text-align: center;
    height: 100%;

    @include media-breakpoint-down(md) {
      padding: 2rem 0.5rem;
    }

    &-icon {
      width: 6rem;
      height: 6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba($secondary, 0.07);
      border-radius: 20rem;
      user-select: none;

      @include media-breakpoint-down(md) {
        width: 5rem;
        height: 5rem;
      }

      img {
        max-width: 3rem;
        max-height: 3rem;
        display: block;

        @include media-breakpoint-down(md) {
          max-width: 2rem;
          max-height: 2rem;
        }
      }
    }

    &-title {
      margin: 1rem 0 0;

      @include media-breakpoint-down(sm) {
        font-size: 1rem;
      }
    }

    &:hover {
      color: $primary;
      transform: translateY(-5px);
    }
  }
}

.homePage {
  .theApp-body {
    padding-top: 0 !important;
  }

  .theHeader {
    background: none;

    &.is-not-top {
      background: $secondary;
    }
  }
}
