.cta {
  position: relative;
  height: 700px;
  margin-bottom: -4rem;

  @include media-breakpoint-down(md) {
    height: 500px;
  }

  @include media-breakpoint-down(sm) {
    height: 400px;
  }

  &-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-image: url('../static/graphic/2@2x.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @include media-breakpoint-down(md) {
      background-position: 10% center;
    }
  }

  .container {
    display: flex;
    justify-content: flex-end;
    height: 100%;
    align-items: center;
  }

  &-content {
    position: relative;
    z-index: 2;
    max-width: 500px;
  }

  &-title {
    font-size: 2rem;
  }
}
