.appBranch {
  transition: 200ms;
  position: relative;
  height: 100%;
  z-index: 2;
  overflow: hidden;
  background: $white;
  padding: 1rem;

  &-header {
    iframe {
      display: block;
    }
  }

  &-body {
    >* {
      margin: 10px 0 0 0;
    }
  }

  &-meta {
    padding: 0;
    margin: 0;
    font-size: .9rem;
    list-style-type: none;
    color: rgba($secondary, .5);

    &:not(:first-child) {
      margin-top: 5px;
    }

    >* {
      display: flex;

      .icon {
        margin: .1rem 0 0 .4rem;

      }

      &:not(:first-child) {
        margin-top: 5px;
      }
    }

  }
}
