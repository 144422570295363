$icomoon-font-family: "Tazweed" !default;
$icomoon-font-path: "../static/fonts" !default;

$icon-sort-ascending: "\e938";
$icon-sort-descending: "\e939";
$icon-sort-alphabetical-ascending: "\e93a";
$icon-sort-alphabetical-descending: "\e93b";
$icon-minus: "\e929";
$icon-view-list: "\e934";
$icon-view-grid: "\e933";
$icon-chevron-left: "\e932";
$icon-chevron-right: "\e931";
$icon-check-bold: "\e930";
$icon-search1: "\e912";
$icon-mail: "\e913";
$icon-phone: "\e924";
$icon-location: "\e925";
$icon-check: "\e926";
$icon-edit: "\e92a";
$icon-out: "\e92b";
$icon-book: "\e92c";
$icon-user: "\e92d";
$icon-star-outline: "\e92e";
$icon-star: "\e92f";
$icon-web: "\e906";
$icon-earth: "\e914";
$icon-globe: "\e917";
$icon-usd: "\e918";
$icon-currency-usd: "\e919";
$icon-cart: "\e923";
$icon-share-outline: "\e91b";
$icon-reply: "\e91c";
$icon-share-variant: "\e91d";
$icon-share: "\e91e";
$icon-thumb-up-outline: "\e920";
$icon-thumb-down-outline: "\e922";
$icon-play: "\e91a";
$icon-email-outline: "\e904";
$icon-cellphone: "\e905";
$icon-clock-outline: "\e907";
$icon-eye-outline: "\e90a";
$icon-account-circle-outline: "\e90b";
$icon-comment-outline: "\e90c";
$icon-close1: "\e90d";
$icon-menu: "\e90e";
$icon-play-circle: "\e90f";
$icon-play-circle-outline: "\e910";
$icon-search: "\e911";
$icon-whatsapp: "\e900";
$icon-refresh: "\e916";
$icon-snapchat-ghost: "\e901";
$icon-list: "\e935";
$icon-list1: "\e936";
$icon-grid: "\e937";
$icon-icons: "\e937";
$icon-clear: "\e921";
$icon-close: "\e921";
$icon-add: "\e91f";
$icon-youtube: "\e927";
$icon-linkedin: "\e928";
$icon-brand: "\e928";
$icon-social: "\e928";
$icon-instagram: "\e902";
$icon-twitter: "\e903";
$icon-brand1: "\e903";
$icon-tweet: "\e903";
$icon-social1: "\e903";
$icon-quote: "\e908";
$icon-google: "\e915";
$icon-facebook: "\e909";
$icon-brand2: "\e909";
$icon-social2: "\e909";



@font-face {
  font-family: "#{$icomoon-font-family}";
  src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?ald1yd");
  src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?ald1yd#iefix") format("embedded-opentype"),
  url("#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?ald1yd") format("truetype"),
  url("#{$icomoon-font-path}/#{$icomoon-font-family}.woff?ald1yd") format("woff"),
  url("#{$icomoon-font-path}/#{$icomoon-font-family}.svg?ald1yd##{$icomoon-font-family}") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "#{$icomoon-font-family}" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon {
  &-xl {
    font-size: 3rem;
  }

  &-lg {
    font-size: 2rem;
  }
}

.icon-sort-ascending {
  &:before {
    content: $icon-sort-ascending;
  }
}

.icon-sort-descending {
  &:before {
    content: $icon-sort-descending;
  }
}

.icon-sort-alphabetical-ascending {
  &:before {
    content: $icon-sort-alphabetical-ascending;
  }
}

.icon-sort-alphabetical-descending {
  &:before {
    content: $icon-sort-alphabetical-descending;
  }
}

.icon-minus {
  &:before {
    content: $icon-minus;
  }
}

.icon-view-list {
  &:before {
    content: $icon-view-list;
  }
}

.icon-view-grid {
  &:before {
    content: $icon-view-grid;
  }
}

.icon-chevron-left {
  &:before {
    content: $icon-chevron-left;
  }
}

.icon-chevron-right {
  &:before {
    content: $icon-chevron-right;
  }
}

.icon-check-bold {
  &:before {
    content: $icon-check-bold;
  }
}

.icon-search1 {
  &:before {
    content: $icon-search1;
  }
}

.icon-mail {
  &:before {
    content: $icon-mail;
  }
}

.icon-phone {
  &:before {
    content: $icon-phone;
  }
}

.icon-location {
  &:before {
    content: $icon-location;
  }
}

.icon-check {
  &:before {
    content: $icon-check;
  }
}

.icon-edit {
  &:before {
    content: $icon-edit;
  }
}

.icon-out {
  &:before {
    content: $icon-out;
  }
}

.icon-book {
  &:before {
    content: $icon-book;
  }
}

.icon-user {
  &:before {
    content: $icon-user;
  }
}

.icon-star-outline {
  &:before {
    content: $icon-star-outline;
  }
}

.icon-star {
  &:before {
    content: $icon-star;
  }
}

.icon-web {
  &:before {
    content: $icon-web;
  }
}

.icon-earth {
  &:before {
    content: $icon-earth;
  }
}

.icon-globe {
  &:before {
    content: $icon-globe;
  }
}

.icon-usd {
  &:before {
    content: $icon-usd;
  }
}

.icon-currency-usd {
  &:before {
    content: $icon-currency-usd;
  }
}

.icon-cart {
  &:before {
    content: $icon-cart;
  }
}

.icon-share-outline {
  &:before {
    content: $icon-share-outline;
  }
}

.icon-reply {
  &:before {
    content: $icon-reply;
  }
}

.icon-share-variant {
  &:before {
    content: $icon-share-variant;
  }
}

.icon-share {
  &:before {
    content: $icon-share;
  }
}

.icon-thumb-up-outline {
  &:before {
    content: $icon-thumb-up-outline;
  }
}

.icon-thumb-down-outline {
  &:before {
    content: $icon-thumb-down-outline;
  }
}

.icon-play {
  &:before {
    content: $icon-play;
  }
}

.icon-email-outline {
  &:before {
    content: $icon-email-outline;
  }
}

.icon-cellphone {
  &:before {
    content: $icon-cellphone;
  }
}

.icon-clock-outline {
  &:before {
    content: $icon-clock-outline;
  }
}

.icon-eye-outline {
  &:before {
    content: $icon-eye-outline;
  }
}

.icon-account-circle-outline {
  &:before {
    content: $icon-account-circle-outline;
  }
}

.icon-comment-outline {
  &:before {
    content: $icon-comment-outline;
  }
}

.icon-close1 {
  &:before {
    content: $icon-close1;
  }
}

.icon-menu {
  &:before {
    content: $icon-menu;
  }
}

.icon-play-circle {
  &:before {
    content: $icon-play-circle;
  }
}

.icon-play-circle-outline {
  &:before {
    content: $icon-play-circle-outline;
  }
}

.icon-search {
  &:before {
    content: $icon-search;
  }
}

.icon-whatsapp {
  &:before {
    content: $icon-whatsapp;
  }
}

.icon-refresh {
  &:before {
    content: $icon-refresh;
  }
}

.icon-snapchat-ghost {
  &:before {
    content: $icon-snapchat-ghost;
  }
}

.icon-list {
  &:before {
    content: $icon-list;
  }
}

.icon-list1 {
  &:before {
    content: $icon-list1;
  }
}

.icon-grid {
  &:before {
    content: $icon-grid;
  }
}

.icon-icons {
  &:before {
    content: $icon-icons;
  }
}

.icon-clear {
  &:before {
    content: $icon-clear;
  }
}

.icon-close {
  &:before {
    content: $icon-close;
  }
}

.icon-add {
  &:before {
    content: $icon-add;
  }
}

.icon-youtube {
  &:before {
    content: $icon-youtube;
  }
}

.icon-linkedin {
  &:before {
    content: $icon-linkedin;
  }
}

.icon-brand {
  &:before {
    content: $icon-brand;
  }
}

.icon-social {
  &:before {
    content: $icon-social;
  }
}

.icon-instagram {
  &:before {
    content: $icon-instagram;
  }
}

.icon-twitter {
  &:before {
    content: $icon-twitter;
  }
}

.icon-brand1 {
  &:before {
    content: $icon-brand1;
  }
}

.icon-tweet {
  &:before {
    content: $icon-tweet;
  }
}

.icon-social1 {
  &:before {
    content: $icon-social1;
  }
}

.icon-quote {
  &:before {
    content: $icon-quote;
  }
}

.icon-google {
  &:before {
    content: $icon-google;
  }
}

.icon-facebook {
  &:before {
    content: $icon-facebook;
  }
}

.icon-brand2 {
  &:before {
    content: $icon-brand2;
  }
}

.icon-social2 {
  &:before {
    content: $icon-social2;
  }
}
