.sidebar {
  background: $white;
  box-shadow: $box-shadow;
  padding: 2rem;

  &-nav {
    list-style-type: none;
    padding: 0;
    margin: 0;

    >li {

      padding: 1rem 0;

      &:not(:last-child) {
        border-bottom: 1px solid rgba($secondary, .1);
      }

      >ul {
        list-style-type: none;
        padding: 0;
        margin: 0;


        >li {
          margin-bottom: 10px;
          color: rgba($secondary, .5);
          font-size: 12px;

          >a {
            display: flex;
            align-items: center;
            justify-content: space-between;
            transition: color .2s ease;

            >span:nth-child(2) {
              color: $primary;
            }
          }
        }
      }
    }

    &-header {
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: color .25s ease;

      &-title {
        margin: 0;
        line-height: 1;
      }

      &-icon {
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 20rem;
        background: $primary;
        color: $white;
        font-size: 1.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
        margin-left: .4rem;

        .icon-minus {
          display: none;
        }
      }

      &:hover {
        color: $primary;
      }
    }

    &-body {
      max-height: 0px;
      overflow: hidden;
      @include scrollbar;
    }

    &-item.is-active {
      .sidebar-nav {
        &-body {
          max-height: 400px;
          overflow-x: hidden;
          overflow-y: auto;
          padding: 0 0 0 .5rem !important;
          margin: 1rem 0 0;
        }

        &-header {
          &-icon {
            .icon-minus {
              display: inline-flex;

            }

            .icon-add {
              display: none;
            }
          }
        }


      }
    }
  }

  &-filter {
    padding: 40px 0 10px
  }

}
