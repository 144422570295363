// salcss
[data-sal|="slide"] {
  transition-timing-function: ease-in-out;
}

[data-sal="slide-up-lg"] {
  transform: translateY(100px);
}

.swiper {
  &-slide {
    height: auto !important;
  }
}

.swiper-pagination-bullet {
  background: rgba($dark, 0.5);
  opacity: 1;
  margin: 0 2px;
  outline: 0;

  &-active {
    background: $primary;
  }
}

.swiper-pagination.is-down {
  position: static !important;
  transform: none !important;
  margin: 20px auto 0px !important;
}

.sliderButtons {

  &-prev,
  &-next {
    width: 2.5rem;
    height: 2.5rem;
    background: $white;
    box-shadow: $box-shadow;
    font-size: 2rem;
    border-radius: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 0;
    cursor: pointer;
    margin: 0 1.5rem;
    transition: 200ms;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity 250ms ease-in-out;
    z-index: 2;

    &:hover {
      color: $primary;
    }
  }

  &-prev {
    right: 0;
    left: auto;
  }

  &-wrapper {
    position: relative;

    &:hover,
    &.is-visable-arrows {
      .sliderButtons {

        &-next,
        &-prev {
          &.swiper-button-disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }

          opacity: 1;
        }
      }
    }
  }
}

.noUi {
  &-connect {
    background: $primary;
  }

  &-handle {
    box-shadow: none;

    &:focus {
      outline: 0;
    }
  }

  &-target {
    background: $white;
    border-color: $gray-400;
  }


}
