.publishers {
  &-header {
    padding: 6rem 0;
    position: relative;
    text-align: center;
    background-color: $secondary;
    color: $white;

    &-bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-repeat: no-repeat;
      background-size: cover;
      opacity: .3;
      background-position: center;
    }

    &-content {
      position: relative;
      z-index: 2;
      max-width: 500px;
      margin: 0 auto;
      line-height: 1.5;

    }
  }


}
