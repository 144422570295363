.aboutHeader {

  position: relative;
  height: 1000px;

  @include media-breakpoint-down(xl) {
    height: 800px;
  }

  @include media-breakpoint-down(lg) {
    height: 600px;

  }

  &-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url('../static/graphic/5@2x.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;
  }

  &-content {
    position: relative;
    z-index: 3;
    max-width: 600px;
    padding-top: 8rem;

    span {
      font-size: 1.2rem;
    }
  }
}

.aboutCard {
  display: flex;

  &-icon {
    width: 5rem;
    height: 5rem;
    flex: 0 0 5rem;
    border-radius: 20rem;
    background: rgba($secondary, .07);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;

    @include media-breakpoint-down(md) {
      width: 4rem;
      height: 4rem;
      flex: 0 0 4rem;
    }

    img {
      max-width: 2.5rem;
      max-height: 2.5rem;

      @include media-breakpoint-down(md) {
        max-width: 2rem;
        max-height: 2rem;
      }
    }
  }

}

.aboutUser {
  padding: 2rem;
  background: $white;
  border-radius: $border-radius;
  text-align: center;

  &-header {
    width: 4rem;
    height: 4rem;
    border-radius: 20rem;
    overflow: hidden;
    margin: 0 auto;
  }

  &-body {
    h5 {
      margin: .5rem 0 .2rem;
    }

    font-size: 12px;
  }
}

.aboutCta {
  padding: 15rem 0;
  margin-bottom: -4rem;
  position: relative;

  @include media-breakpoint-down(md) {
    padding: 10rem 0;
  }

  @include media-breakpoint-down(sm) {
    padding: 6rem 0;
  }

  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: url('../static/graphic/4@2x.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    display: block;
  }

  &-content {
    position: relative;
    z-index: 2;
    max-width: 450px;
  }
}
