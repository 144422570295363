.theFooter {
  padding: 4rem 0 0;
  background: $secondary;
  color: $white;
  font-size: 0.9rem;
  margin-top: 4rem;

  &-nav {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0 -5px;
    flex-wrap: wrap;

    >li {
      width: 33%;
      padding: 0 5px;
      margin-bottom: 5px;

      @include media-breakpoint-down(xs) {
        width: 50%;
      }

    }
  }

  &-list {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      margin-bottom: 10px;
    }
  }

  &-bottom {
    border-top: 1px solid rgba($white, .1);

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      >* {
        margin: 0;
        padding: 1rem 0;
      }
    }
  }



  a:hover {
    color: $primary;
  }
}
