.appImage {
  position: relative;
  padding-top: aspectRatio(5, 6);
  user-select: none;
  z-index: 2;
  overflow: hidden;
  display: block;
  background: $gray-400;

  >img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    left: 0;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
  }

  &-50 {
    @include media-breakpoint-up(md) {
      width: 50vw;

      &.is-right {
        float: right;
      }
    }
  }

  &-50-lg {
    @include media-breakpoint-up(lg) {
      width: 50vw;

      &.is-right {
        float: right;
      }
    }
  }

  &-link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    font-size: 3rem;
    transition: 300ms;
    color: $white;

    &:hover {
      color: $white;
      transform: scale(1.2);
    }
  }

  &.is-1-1 {
    padding-top: aspectRatio(1, 1);
  }

  &.is-3-2 {
    padding-top: aspectRatio(3, 2);
  }

  &.is-3-1 {
    padding-top: aspectRatio(3, 1);
  }

  &.is-4-1 {
    padding-top: aspectRatio(4, 1);
  }
}
