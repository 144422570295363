.appCard {
  transition: 200ms;
  position: relative;
  height: 100%;
  z-index: 2;
  overflow: hidden;
  background: $white;
  padding: 1rem;

  @include media-breakpoint-down(xs) {
    padding: 0.5rem;
  }

  &-url {
    position: absolute;
    z-index: 4;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &-header {
    position: relative;
    overflow: hidden;
  }

  &-title {
    transition: 0.25s ease;
    font-size: 1rem;
    margin: 0;

    @include media-breakpoint-down(xs) {
      font-size: 13px;
    }

    &:not(:first-child) {
      margin: 5px 0 0 0;
    }
  }

  &-price {
    margin: 5px 0 0 0;
  }

  &-meta {
    padding: 0;
    margin: 0;
    font-size: 0.9rem;
    list-style-type: none;
    color: rgba($secondary, 0.5);

    &:not(:first-child) {
      margin-top: 5px;
    }

    > * {
      display: block;

      &:not(:first-child) {
        margin-top: 5px;
      }
    }
  }

  &-body {
    padding: 10px 0 0;
  }

  .inViewJs-active.appImage {
    transition: 0.25s ease;
  }

  .appStars-wrapper {
    &:not(:first-child) {
      margin-top: 10px;
    }
  }

  &-cart {
    display: none;
    position: absolute;
    z-index: 5;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    bottom: 1rem;
    left: 1rem;
  }

  .show-wide {
    display: none;
  }

  &.is-wide {
    @include media-breakpoint-up(sm) {
      display: flex;
      align-items: center;

      .show-wide {
        display: block;
      }

      .appCard {
        &-cart {
          display: flex;
        }

        &-header {
          width: 140px;
          flex: 0 0 140px;
          margin-left: 1rem;
        }

        &-body {
          padding: 0;
          flex: 1 1 0%;
        }
      }
    }
  }

  &.is-mine {
    box-shadow: $box-shadow;

    .appCard {
      &-price {
        display: none;
      }
    }
  }

  &:hover {
    .appCard {
      &-title {
        color: $primary;
      }
    }

    .inViewJs-active.appImage {
      transform: scale(1.1);
    }
  }
}

#viewToggle {
  .btn {
    padding: 0;
    font-size: 2rem;
    display: inline-flex;

    &.is-active,
    &:hover {
      color: $primary;
    }
  }

  &-container {
    &.is-list {
      [class*="col-"] {
        flex: 0 0 100%;
        max-width: 100%;

        @include media-breakpoint-down(xs) {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }
    }
  }
}
