.appReview {
  position: relative;
  z-index: 2;
  overflow: hidden;
  display: flex;


  &-header {
    width: 3rem;
    height: 3rem;
    border-radius: 20rem;
    overflow: hidden;
    margin-left: 1rem;
  }

  &-body {
    flex: 1 1 0%;
  }

  &-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

  }

  &-content {
    font-size: 12px;
  }


}
