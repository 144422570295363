[dir="ltr"] {
  .choices__list--single {
    padding-right: 16px;
    padding-left: 4px;
  }
  .choices[data-type*="select-one"][dir="rtl"]:after {
    left: auto;
    right: 11.5px;
  }
  .choices__list--dropdown .choices__item--selectable {
    text-align: left !important;
  }
  .choices__list--dropdown .choices__item--selectable:after {
    right: 10px !important;
    left: auto !important;
  }
  .icon-chevron-right,
  .icon-chevron-left,
  .cta-bg,
  .homeIntro-bg,
  .aboutHeader-bg,
  .aboutCta-bg {
    transform: scaleX(-1);
  }
}
