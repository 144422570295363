.advancedSearch {
  display: flex;

  &-1 {
    width: 200px;

    .choices__inner {
      background: $info;
      border-radius: 0 $border-radius $border-radius 0;
    }
  }

  &-2 {
    .choices__inner {
      border-radius: $border-radius 0 0 $border-radius;
    }
  }
}
