@import "./bootstrap";
@import "./fonts";
@import "./icons";
@import "./utils";
@import "./libs";
@import "./components/TheHeader.scss";
@import "./components/TheFooter.scss";
@import "./components/NavOverlay.scss";
@import "./components/AppImage.scss";
@import "./components/Socials.scss";
@import "./components/AppCard.scss";
@import "./components/AppReview.scss";
@import "./components/ScrollToTop.scss";
@import "./components/AppStars.scss";
@import "./components/Cta.scss";
@import "./components/Sidebar.scss";
@import "./components/Book.scss";
@import "./components/QuantityInput.scss";
@import "./components/AppPublisher.scss";
@import "./components/AppBranch.scss";

// Pages
@import "./pages/index.scss";
@import "./pages/full.scss";
@import "./pages/single.scss";
@import "./pages/contact.scss";
@import "./pages/publishers.scss";
@import "./pages/cart.scss";
@import "./pages/user.scss";
@import "./pages/authors.scss";
@import "./pages/about.scss";
@import "./pages/search.scss";
@import "./animation.scss";

// libs
/* rtl:begin:ignore */
@import "./components/choices.scss";
/* rtl:end:ignore */

@import "nouislider/distribute/nouislider.css";
@import "swiper/css/swiper.css";

html {
  direction: rtl;
  font-size: 14px;
}

body {
  text-align: right;
}

a {
  color: inherit;
}

.theApp {
  overflow: hidden;

  &-body {
    padding-top: $header-height;
  }
}

.gutter-lg {
  margin-right: -$grid-gutter-width;
  margin-left: -$grid-gutter-width;

  > [class*="col-"] {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
  }
}

.gutter-sm {
  margin-right: -2px;
  margin-left: -2px;

  > [class*="col-"] {
    padding-left: 2px;
    padding-right: 2px;
  }
}

.btn {
  &-primary {
    color: $white;
  }

  &-wide {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.pagination {
  .page-link {
    border-radius: 20em !important;
    padding: 0.4rem 0.75rem;
    margin: 0 5px;
    font-weight: $headings-font-weight;
  }
}
.modal-header .close {
  margin: -1rem auto -1rem -1rem;
}
/* rtl:begin:ignore */
@import "./ltr.scss";
/* rtl:end:ignore */
