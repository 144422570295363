.theApp {
  transition: opacity 0.2s ease-in-out;
  visibility: hidden;
  opacity: 0;

  &.is-loaded {
    visibility: visible;
    opacity: 1;
  }
}

.inViewJs {
  &.appImage {
    overflow: hidden;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 200%;

      background: $primary;
      transition: 1.5s ease-out;
      transform: translateY(-100%);
      z-index: 2;
    }

    img {
      transform: scale(1.5);
      transition: 0.4s ease-out;
      transition-delay: 0.3s;
      opacity: 0;
    }

  }

  &-active {
    &.appImage {
      &:after {
        transform: translateY(200%);
      }

      .appImage-link {
        opacity: 1;
      }

      img {
        transform: none;
        opacity: 1;
      }
    }
  }
}

/* rtl:begin:ignore */
#cursor {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  pointer-events: none;
  position: fixed;
  top: -10px;
  left: -10px;
  width: 1rem;
  height: 1rem;
  line-height: 1;

  z-index: 9999999;

  /* Smartphones ----------- */
  @media only screen and (max-width: 760px) {
    display: none;
  }

  >span {
    animation: cursorAnim 0.5s infinite alternate;
    display: block;
    color: $secondary;
  }

  &:before {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;
    animation: cursorAnim 0.5s infinite alternate;
    top: -1px;
    left: -1px;
    border: 1px solid $primary;
    border-radius: 20em;
    animation: cursorAnim 0.5s infinite alternate;
  }

  &:after {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;
    border: 8px solid $primary;
    border-radius: 50%;
    opacity: 0.5;
    top: -1px;
    left: -1px;
    animation: cursorAnim2 0.5s infinite alternate;
  }

  &.is-click {
    &:after {
      animation: cursorAnim3 0.5s forwards;
      border-color: $secondary;
    }

    &:before {
      animation: cursorAnim3 0.5s forwards;
      border-color: $secondary;
    }

    >span {
      animation: cursorAnim3 0.5s forwards;
      color: $warning;
    }
  }

  &.is-link {
    &:after {
      // animation: cursorAnim3 0.5s forwards;
      border-color: $secondary;
    }

    &:before {
      animation: cursorAnim3 0.5s forwards;
      border-color: $secondary;
    }

    >span {
      color: $secondary;
    }
  }
}

@keyframes cursorAnim {
  from {
    transform: scale(1.2);
    color: $secondary;
  }

  to {
    transform: scale(0.9);
    color: $primary;
  }
}

@keyframes cursorAnim2 {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(0.4);
  }
}

@keyframes cursorAnim3 {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(3);
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}

/* rtl:end:ignore */
