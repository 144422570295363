.cartItem {
  transition: 200ms;
  position: relative;
  z-index: 2;
  overflow: hidden;
  background: $white;
  padding: 1rem;
  display: flex;
  align-items: center;
  margin-left: -5px;
  margin-right: -5px;

  @include media-breakpoint-down(xs) {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  &-url {
    position: absolute;
    z-index: 4;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  >* {
    margin-left: 5px;
    margin-right: 5px;
  }


  &-header {
    width: 100px;
    position: relative;
  }

  &-body {
    flex: 1 1 0%;
    position: relative;

    @include media-breakpoint-down(xs) {
      padding: 1rem 0;
    }

    >* {
      margin: 10px 0 0 0;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  &-footer {
    @include media-breakpoint-down(xs) {
      margin-top: 1rem;
    }
  }

  &-meta {
    padding: 0;
    margin: 0;
    font-size: .9rem;
    list-style-type: none;
    color: rgba($secondary, .5);

    &:not(:first-child) {
      margin-top: 5px;
    }

    >* {
      display: block;

      &:not(:first-child) {
        margin-top: 5px;
      }
    }

  }

  .btn-circle {
    padding-right: 0.510rem;
    padding-left: 0.510rem;
    font-size: 1.2rem !important;
  }
}

.cartSidebar {
  background: $white;

  text-align: center;
  border-radius: $border-radius;
  overflow: hidden;

  &-main {
    padding: 2rem;
  }

  &-footer {
    background: $gray-400;
    padding: 2rem .5rem;
  }
}

.cartSuccess {
  background: $white;
  text-align: center;
  border-radius: $border-radius;
  overflow: hidden;
  padding: 3rem 2rem;
  width: auto;
  margin: auto;
}
