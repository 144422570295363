.userBox {
  background: $white;
  border-radius: $border-radius;
  padding: 4rem 2rem;
}

.userSidebar {
  background: $white;
  border-radius: $border-radius;
  padding: 4rem 2rem;

  .appImage {
    width: 5rem;
    height: 5rem;
    border-radius: 20rem;
    overflow: hidden;
    padding-top: 0;
    margin: 0 auto;
    box-shadow: $box-shadow;
  }

  .userSidebar-nav {
    list-style-type: none;
    padding: 0;
    margin: 0;

    >li {
      padding: .2rem 0;

      >a {
        padding: .5rem 1rem;
        display: flex;
        align-items: center;
        border-radius: $border-radius;
        font-weight: $headings-font-weight;
        transition: .25s ease;
        font-size: 1.1rem;

        .icon {
          margin-left: 5px;

        }

        &:hover {
          background: $primary;
          color: $white;
        }
      }

      >ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: none;

        >li {
          >a {
            padding: .2rem 2.4rem .2rem 0;

            display: block;


            &:hover {
              color: $primary;
            }
          }
        }
      }

      &.is-active {
        >a {
          background: $primary;
          color: $white;
        }

        >ul {
          display: block;
        }
      }
    }
  }
}
