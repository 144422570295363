.appStars {
  display: inline-flex;
  align-items: center;
  position: relative;
  font-size: 1.1rem;

  &-colored {
    position: absolute;
    right: 0;
    top: 0;
    white-space: nowrap;
    color: $warning;
    overflow: hidden;

  }

  &-uncolored {}

  &-wrapper {
    color: rgba($secondary, .5);
    display: flex;
    align-items: center;

    &.no-count {

      .appStars-count {
        display: none;
      }
    }
  }


}
