// Spacing
$sizeUnit: rem;
$marginKey: "m";
$paddingKey: "p";
$separator: "-";
$sizes: (("none", 0),
  ("xxs", 0.125),
  ("xs", 0.25),
  ("sm", 0.5),
  ("md", 1),
  ("lg", 2),
  ("xl", 4),
  ("xxl", 8));
$positions: (("t", "top"), ("r", "right"), ("b", "bottom"), ("l", "left"));

@function sizeValue($key, $value) {
  @return if($key=="none", 0, $value + $sizeUnit);
}

@each $size in $sizes {
  $sizeKey: nth($size, 1);
  $sizeValue: nth($size, 2);

  .#{$marginKey}#{$separator}#{$sizeKey} {
    margin: sizeValue($sizeKey, $sizeValue);
  }

  .#{$paddingKey}#{$separator}#{$sizeKey} {
    padding: sizeValue($sizeKey, $sizeValue);
  }

  @each $position in $positions {
    $posKey: nth($position, 1);
    $posValue: nth($position, 2);

    .#{$marginKey}#{$posKey}#{$separator}#{$sizeKey} {
      margin-#{$posValue}: sizeValue($sizeKey, $sizeValue);
    }

    .#{$paddingKey}#{$posKey}#{$separator}#{$sizeKey} {
      padding-#{$posValue}: sizeValue($sizeKey, $sizeValue);
    }
  }
}

.mb-col {
  margin-bottom: $grid-gutter-width;
}

.pt-header {
  padding-top: $header-height;

  @include media-breakpoint-down(xs) {
    padding-top: $header-height-xs;
  }
}

.text {

  &-xl {
    font-size: 4rem;

    @include media-breakpoint-down(md) {
      font-size: 3rem;
    }

    @include media-breakpoint-down(xs) {
      font-size: 2.5rem;
    }
  }

  &-lg {
    font-size: 3rem;

    @include media-breakpoint-down(md) {
      font-size: 2.7rem;
    }

    @include media-breakpoint-down(xs) {
      font-size: 2.3rem;
    }
  }
}

.order {
  &-lg {
    @include media-breakpoint-down(lg) {
      order: -1;
    }
  }

  &-md {
    @include media-breakpoint-down(md) {
      order: -1;
    }
  }

  &-sm {
    @include media-breakpoint-down(sm) {
      order: -1;
    }
  }
}
