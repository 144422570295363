.quantityInput {
  display: flex;
  width: 120px;
  position: relative;
  text-align: center;

  &-amount {
    padding-left: 40px;
    padding-right: 40px;
    text-align: center;
  }

  &-increase,
  &-decrease {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
  }

  &-decrease {
    left: 0;
    right: auto;
  }

  .btn {
    &:hover {
      color: $primary;
    }

    &:focus {
      box-shadow: none;
    }
  }
}
